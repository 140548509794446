import React, {useState, useEffect} from "react";
import DetallePago from "./DetallePago";
import printA4 from "../../helpers/A4";
import downprintA4 from "../../helpers/DA4";
import "./styles/Pagos.css";
import {
    FACTURA,
    YAPE,
    BOLETA,
    VALE,
    round,
    EFECTIVO,
    MASTER,
    VISA,
    AMERICAN,
    OTROS,
    PAGO_CREDITO,
    TUNKI,
    RAPPI,
    PLIN,
    decimalAdjust,
    CONTADO,
} from "../../Global";
import EfectivoImg from "./images/efectivo.png";
import Mastercard from "./images/mastercard.png";
import Visa from "./images/visa.png";
import American from "./images/american.png";
import OtrosPagos from "./images/otros.png";
import Credito from "./images/credito.png";
import Cliente from "./images/cliente.png";
import Yape from "./images/yape.png";
import Plin from "./images/plin logo.png";
import Rappi from "./images/rappi logo.png";
import Tunki from "./images/tunki logo.png";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import {BotonGestionPreventas, BotonNuevaPreventa} from "../../componentes/Plantillas/Botones";
import { GuiaRemisionSelect } from "./GuiaRemisionSelect";
import Modal from "../../componentes/clientes/ClienteModal";
import { notificarMsg } from "../../componentes/Almacenes/AlmacenNotify";

const DEFAULT_VALIDATION_CODE = 'XXXXXXXX'


const PagosUI = props => {

    const [showNuevaPreventa, setShowNuevaPreventa] = useState(false);
    const [showModalValidacion, setShowModalValidacion] = useState(false);
    const [modalValidacionTipoPago, setModalValidacionTipoPago] = useState(null);
    const [codigosValidacionUsuario, setCodigosValidacionUsuario] = useState([]);
    const [typedCodigoUsuario, setTypedCodigoUsuario] = useState("")
    const [disableCredit, setDisableCredit] = useState(false);
    const [disableAll, setDisableAll] = useState(false);
    const [shouldRemovePayments, setShouldRemovePayments] = useState(false);
    const [confEmpresa, setConfEmpresa] = useState(false);

    useEffect(() => {
        const task = async () => {
            const codigos = await fetchCodigosValidacion()
            setCodigosValidacionUsuario(codigos);

            const confEmpresa = await fetchConfEmpresa()
            setConfEmpresa(confEmpresa)
        }

        const clienteVarios = props.preventa.NroTipoDocumento == "99999999";
        setDisableCredit(clienteVarios)

        task();
    }, [])

    useEffect(() => {
        const clienteVarios = props.preventa.NroTipoDocumento == "99999999";
        const hasCreditPayment = props.Pagos.some(p => p.IdTipoPago === PAGO_CREDITO);

        setDisableCredit(clienteVarios || hasCreditPayment);

        if (props.Pagos.length == 0) {
            setDisableAll(false);
            return;
        }

        const lastPaymentIsContado = props.Pagos[props.Pagos.length - 1].IdTipoPago === CONTADO;
        setDisableAll(lastPaymentIsContado);
    }, [props.Pagos.length])

    useEffect(() => {
        const lastPaymentIsContado = props.Pagos.length > 0 && props.Pagos[props.Pagos.length - 1].IdTipoPago === CONTADO;
        if (lastPaymentIsContado) {
            setDisableAll(true);
            return;
        }

        const isCompleted = round(props.Total - props.TotalPagado, 2) <= 0;
        setDisableAll(isCompleted)
    }, [props.TotalPagado])

    useEffect(() => {
        if (!Array.isArray(props.Pagos) || props.Pagos.length == 0) {
            setShouldRemovePayments(false);
            return;
        }

        let fromLeft = false;
        let fromRight = false;

        let restante = props.Total;

        for (let i = 0; i < props.Pagos.length; i++) {
            const pago = props.Pagos[i];
            restante = restante - pago.Monto;

            if (restante < 0 && props.Pagos.length - 1 != i) {
                fromLeft = true
                break;
            }
        }

        restante = props.Total;

        for (let i = props.Pagos.length - 1; i >= 0; i--) {
            const pago = props.Pagos[i];
            restante = restante - pago.Monto;

            if (restante < 0 && i > 0) {
                fromRight = true
                break;
            }
        }

        setShouldRemovePayments(fromLeft || fromRight)
    }, [props.TotalPagado])

    const fetchConfEmpresa = async () => {
        try {
            const req = await fetch(`/api/config/emp`);
            const conf = await req.json();

            if (!('showExactChange' in conf)) {
                conf['showExactChange'] = false;
            };

            return conf;
        } catch (e) {
            console.error(e)
        }
    }

    const checkPerm = async (perm, tipoPago) => {
        try {
            const req = await fetch(`/api/usuarios/${perm}/validar`);

            if (!req.ok) {
                throw new Error();
            }

            const data = await req.json()

            setModalValidacionTipoPago(tipoPago)
            setShowModalValidacion(data.codigoConfirmacion);
        } catch (e) {
            props.handleTipoPago(tipoPago)();
            setShowModalValidacion(false);
        }
    }

    const fetchCodigosValidacion = async () => {
        try {
            const req = await fetch('/api/usuarios/codigoConfirmacion/sucursal');

            if (!req.ok) {
                throw new Error();
            }

            const res = await req.json();

            return res.map(c => c.Codigo);
        } catch (e) {
            return DEFAULT_VALIDATION_CODE
        }
    }

    const validarCodigoUsuario = () => {
        const validCode = codigosValidacionUsuario.some(c => c == typedCodigoUsuario)
        
        if (validCode) {
            props.handleTipoPago(modalValidacionTipoPago)();
            setShowModalValidacion(false);
            notificarMsg("Validacion completada exitosamente.");
        } else {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')
        }
        setTypedCodigoUsuario("")
    }

    const handleFirstButtonClick = () => {
        if (shouldRemovePayments) {
            alert("El orden de los medios de pago seleccionados no son válidos. Verique si todos son necesarios e inténtelo otra vez.")
            return;
        }
        setShowNuevaPreventa(true);
        props.handlePagar();
    };

    return (
        <>
            <div className="pagos__container" tabIndex="-1">
                <div className="row pagos">
                    <div className="preventa__container_data col-12 col-sm-6">
                        <div className="row ">
                            <div className="col-12 col-sm-6 mt-3">
                                {showNuevaPreventa && (
                                    <BotonGestionPreventas
                                        to="/gestionpreventas"
                                        title="Lista de Preventas Pendientes"
                                        tabIndex="0"
                                    />
                                )}
                                {props.isLoading ? null :
                                    props.hasPayed ? null :
                                        props.permisosEditar ? <button
                                            type="button"
                                            className="boton__naranja ml-1"
                                            title="Editar Preventa"
                                            onClick={() =>
                                                props.setRedirect("/preVentas/edit/" + props.IdPreventa)
                                            }
                                        >
                                            <i className="fa fa-edit" />
                                        </button> : null
                                }
                            </div>
                            <div className="col-12 col-sm-6 d-flex justify-content-end preventa__cliente mt-3">
                                <input
                                    type="image"
                                    src={Cliente}
                                    data-toggle="tooltip"
                                    title="Datos del Cliente"
                                    width="40px"
                                    height="50px"
                                    tabIndex="0"
                                />
                                <div className="preventa__cliente--contenido">
                                    <p>{props.preventa.Alias}</p>
                                    <p>
                                        <strong>{props.preventa.NroTipoDocumento}</strong>
                                    </p>
                                    <p>{props.preventa.RazonSocial}</p>
                                </div>
                            </div>
                        </div>
                        <div className="preventa__carrito">
                            <ReactTable
                                tabIndex="0"
                                id="table-productos-to-xls"
                                data={props.detallePreventa}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Descripción",
                                                id: "NombreProductoConPresentacion",
                                                accessor: d => d.NombreProductoConPresentacion,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["NombreProductoConPresentacion"]
                                                    }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cantidad",
                                                id: "Cantidad",
                                                accessor: d => d.Cantidad,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Cantidad"]
                                                    }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            textAlign: "right"
                                                        }
                                                    };
                                                }
                                            },
                                            {
                                                Header: "Precio",
                                                id: "PrecioVenta",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.PrecioVenta, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["PrecioVenta"]
                                                    }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            textAlign: "right"
                                                        }
                                                    };
                                                }
                                            },
                                            {
                                                Header: "Descuento",
                                                id: "Descuento",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.Descuento, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Descuento"]
                                                    }),
                                                filterAll: true,
                                            },
                                            {
                                                Header: "Total",
                                                id: "Total",
                                                accessor: d => `S./ ${decimalAdjust('floor', d.Total, -2)}`,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Total"]
                                                    }),
                                                filterAll: true,
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={8}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                noDataText="No se encontraron registros"
                                loadingText="Cargando..."
                                ofText="de"
                                rowsText="filas"
                                pageText="Página"
                                pageSizeOptions={[8, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>

                    <div className="encabezado col-12 col-sm-6 encabezado-resp">
                        <div className="mt-1">PAGOS - {props.TipoModalidadPago}</div>
                        <div className="pagos__tipo--comprobante">
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === FACTURA
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(2);
                                }}
                                hidden={props.TipoComprobante !== FACTURA}
                            >
                                Factura
                            </button>
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === BOLETA
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(4);
                                }}
                                hidden={props.TipoComprobante !== BOLETA}
                            >
                                Boleta
                            </button>
                            <button
                                type="button"
                                tabIndex="0"
                                className={
                                    props.TipoComprobante === VALE
                                        ? "pagos__tipo--comprobante--item--active"
                                        : "pagos__tipo--comprobante--item"
                                }
                                value={props.TipoComprobante}
                                onClick={function () {
                                    props.handleTipoComprobante(1);
                                }}
                                hidden={props.TipoComprobante !== VALE}
                            >
                                Vale
                            </button>
                        </div>
                        <div className="detalles__pago">
                            <div className="detalles__pago__item">

                                {props.Pagos.map((pago, index) => {
                                    return (
                                        <DetallePago
                                            key={`pay_${index}`}
                                            IdTipoPago={pago.IdTipoPago}
                                            Monto={pago.Monto}
                                            Referencia={pago.Referencia}
                                            handleFocus={props.handleFocus}
                                            handleMontoPago={async function (event) {
                                                await props.updateMontoPago(index, event.target.value);
                                            }}
                                            handleReferenciaPago={function (event) {
                                                props.updateReferenciaPago(index, event.target.value);
                                            }}
                                            handleSelectPlazosCredito={async function (event) {
                                                await props.updateFechaPago(index, event.target.value);
                                            }}
                                            PlazoCredito={pago.PlazoCredito}
                                            handlePagoEnterKeyUp={props.handlePagoEnterKeyUp}
                                            handleRemovePago={function () {
                                                props.removePago(index);
                                            }}
                                            fechaPago={pago.FechaPago}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className="pagos__methods--container w-100">
                            <div id="carousel-menu-method-pagos"
                                 className="carousel carousel-dark slide w-100 botones-desk" data-interval="false">
                                <div className="carousel-inner ">
                                    <div className="carousel-item active ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={EfectivoImg}
                                                    className="btn btn-outline-success"
                                                    onClick={props.handleTipoPago(EFECTIVO)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Efectivo"
                                                    tabIndex="1"
                                                    disabled={disableAll || props.PagoEfectivoUnico || props.creditoSelected}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="pagos__methods ml-2">
                                                <input
                                                    type="image"
                                                    src={Yape}
                                                    className="btn btn-yape"
                                                    onClick={props.handleTipoPago(YAPE)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Yape"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                            <div className="pagos__methods ml-2">
                                                <input
                                                    type="image"
                                                    src={Visa}
                                                    className="btn btn-outline-primary"
                                                    onClick={props.handleTipoPago(VISA)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    disabled={disableAll || props.creditoSelected}
                                                    title="Visa"
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={American}
                                                    className="btn btn-outline-info"
                                                    onClick={props.handleTipoPago(AMERICAN)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="American Express"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                            <div className="pagos__methods ml-2">
                                                <input
                                                    type="image"
                                                    src={Mastercard}
                                                    className="btn btn-outline-danger"
                                                    onClick={props.handleTipoPago(MASTER)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Mastercard"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                            <div className="pagos__methods ml-2">
                                                <input
                                                    type="image"
                                                    src={OtrosPagos}
                                                    className="btn btn-outline-warning"
                                                    onClick={() => checkPerm('pago-otros', OTROS)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Otros métodos de pago"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Credito}
                                                    className="btn btn-outline-danger"
                                                    onClick={() => checkPerm('vender-a-credito', PAGO_CREDITO)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Crédito"
                                                    disabled={disableAll || disableCredit}
                                                    tabIndex="1"
                                                />
                                            </div>
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Plin}
                                                    className="btn btn-yape"
                                                    onClick={props.handleTipoPago(PLIN)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Yape"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Tunki}
                                                    className="btn btn-yape"
                                                    onClick={props.handleTipoPago(TUNKI)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Yape"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                            <div className="pagos__methods ml-2">
                                                <input
                                                    type="image"
                                                    src={Rappi}
                                                    className="btn btn-fpay"
                                                    onClick={props.handleTipoPago(RAPPI)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Rappi"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev btn-atras" href="#carousel-menu-method-pagos"
                                   role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carousel-menu-method-pagos" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                            <div id="carousel-menu-method-pagos-2"
                                 className="carousel carousel-dark slide w-100 botones-resp" data-interval="false">
                                <div className="carousel-inner ">
                                    <div className="carousel-item active ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={EfectivoImg}
                                                    className="btn btn-outline-success"
                                                    onClick={props.handleTipoPago(EFECTIVO)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Efectivo"
                                                    tabIndex="1"
                                                    disabled={disableAll || props.PagoEfectivoUnico || props.creditoSelected}
                                                    autoFocus
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Yape}
                                                    className="btn btn-yape"
                                                    onClick={props.handleTipoPago(YAPE)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Yape"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Visa}
                                                    className="btn btn-outline-primary"
                                                    onClick={props.handleTipoPago(VISA)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    disabled={disableAll || props.creditoSelected}
                                                    title="Visa"
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Mastercard}
                                                    className="btn btn-outline-danger"
                                                    onClick={props.handleTipoPago(MASTER)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Mastercard"
                                                    disabled={props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={American}
                                                    className="btn btn-outline-info"
                                                    onClick={props.handleTipoPago(AMERICAN)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="American Express"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={OtrosPagos}
                                                    className="btn btn-outline-warning"
                                                    onClick={props.handleTipoPago(OTROS)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Otros métodos de pago"
                                                    disabled={disableAll || props.creditoSelected}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item ">
                                        <div className="pagos__methods--container">
                                            <div className="pagos__methods">
                                                <input
                                                    type="image"
                                                    src={Credito}
                                                    className="btn btn-outline-danger"
                                                    onClick={props.handleTipoPago(PAGO_CREDITO)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Crédito"
                                                    disabled={disableAll || disableCredit}
                                                    tabIndex="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev btn-atras" href="#carousel-menu-method-pagos-2"
                                   role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carousel-menu-method-pagos-2" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                        <div className="info__input__container">
                            {/*<div className="input__container">*/}
                            {/*    <label>Descuento</label>*/}
                            {/*    <input*/}
                            {/*        className="input"*/}
                            {/*        aria-label="Soles"*/}
                            {/*        placeholder="0.00"*/}
                            {/*        value={props.Descuento || "0"}*/}
                            {/*        onChange={props.handleDescuento}*/}
                            {/*        type="number"*/}
                            {/*        onFocus={e => e.target.select()}*/}
                            {/*        tabIndex="0"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="input__container">
                                <label>Vuelto</label>
                                <input
                                    style={{overflow: "hidden"}}
                                    className="input"
                                    tabIndex="0"
                                    aria-label="Soles"
                                    placeholder="0.00"
                                    readOnly
                                    value={
                                        (() => {
                                            if (!props.Vuelto) {
                                                return 0.00
                                            }

                                            if (!confEmpresa.showExactChange) {
                                                return (props.TotalPagado - props.TotalRedondeado) && (props.TotalPagado - props.TotalRedondeado).toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                            } else {
                                                return decimalAdjust("floor", props.Vuelto, -2)
                                            }
                                        })()
                                    }
                                />
                            </div>
                            <div className="input__container">
                                <label>Observaciones</label>
                                <input
                                    style={{overflow: "hidden"}}
                                    className="input"
                                    aria-label="Observaciones"
                                    placeholder=""
                                    value={props.Observaciones}
                                    onChange={props.handleObservaciones}
                                    tabIndex="0"
                                />
                            </div>
                            <div className="input__container">
                                <label>Orden Compra</label>
                                <input
                                    style={{overflow: "hidden"}}
                                    className="input"
                                    aria-label="Observaciones"
                                    placeholder=""
                                    tabIndex="0"
                                    value={props.OrdenCompra}
                                    onChange={props.handleOrdenCompra}
                                />
                            </div>

                            <GuiaRemisionSelect onChange={props.handleGuiaRemision}/>
                        </div>
                    </div>
                </div>

                <div className="totals__container row">
                    <div className="totals__container--item col-12 col-sm-6">
                        <p value={props.TotalGravadas}>
                            <strong>
                                {props.TotalGravadas > 0 &&
                                    <span>
                                        <span>Gravado</span><br/>
                                        <span
                                            style={{whiteSpace: 'nowrap'}}>S/. {Number(props.TotalGravadas).toFixed(2)}</span>
                                    </span>
                                }
                            </strong>
                        </p>
                        <p value={props.TotalInafectas}>
                            <strong>
                                {props.TotalInafectas > 0 &&
                                    "Inafecto" + "S/. " +
                                    props.TotalInafectas}
                            </strong>
                        </p>
                        <p value={props.TotalExoneradas}>
                            <strong>
                                {props.TotalExoneradas > 0 &&
                                    "Exonerado" + "S/. " +
                                    props.TotalExoneradas}
                            </strong>
                        </p>
                        <p value={props.Gratuitas}>
                            <strong>
                                {props.Gratuitas > 0 &&
                                    "Gratuito" + "S/. " +
                                    props.Gratuitas}
                            </strong>
                        </p>

                        <p value={props.TotalICBPER}>
                            <strong>
                                {props.TotalICBPER > 0 &&
                                    "ICBPER" + "S/. " +
                                    props.TotalICBPER}
                            </strong>
                        </p>
                        <p value={props.TotalIGV}>
                            <strong>
                                {props.TotalIGV > 0 &&
                                    <span>
                                        <span>IGV</span><br/>
                                        <span
                                            style={{whiteSpace: 'nowrap'}}>S/. {Number(props.TotalIGV).toFixed(2)}</span>
                                    </span>
                                }
                            </strong>
                        </p>
                        <p>
                            <strong>
                                {props.redondeo > 0 &&
                                    <span>
                                        <span>Redondeo</span><br/>
                                        <span
                                            style={{whiteSpace: 'nowrap'}}>S/. {decimalAdjust('floor', props.redondeo, -2)}</span>
                                    </span>
                                }
                            </strong>
                        </p>
                    </div>
                    <div className="totals__container--item-total col-12 col-sm-2 total-resp">
                        <span style={{display: "block", margin: "auto"}}>
                            {"S/" + props.Total.toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </span>
                    </div>

                    <div className="totals__container--item col-12 col-sm-4 no-pad d-flex justify-content-between">
                        <p style={{margin: "0 0 0 1rem"}}>
                            <strong>
                                {round(props.Total - props.TotalPagado, 2) > 0 &&
                                    "Pendiente S/" +
                                    round(props.Total - props.TotalPagado, 2).toLocaleString(
                                        "en",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }
                                    )}
                            </strong>
                        </p>

                        {props.isLoading ? "Cargando" : props.error ? "Ha ocurrido un error" : (
                            props.hasPayed ? <BotonNuevaPreventa
                                to="/pre-invoice/add"
                                title="Lista de Preventas Pendientes"
                                tabIndex="0"
                                style={{height: "100%"}}
                            /> : <button
                                className="pagos__boton"
                                onClick={handleFirstButtonClick}
                                tabIndex="3"
                                hidden={
                                    !props.detallePreventa.length
                                }
                                disabled={
                                    !(props.TotalPagado >= props.Total && props.ClickPagar)
                                }
                            >
                                PAGAR
                            </button>
                        )}

                        {props.Venta && props.hasPayed ? (
                            <>
                                <div className="botones-desk">
                                    <button
                                        className="boton__verdeClaro--letras ml-1 ml-5"
                                        onClick={() =>
                                            props.printTicketVenta(props.Venta, props.DetallesVenta)
                                        }
                                        tabIndex="4"
                                        style={{height: "100%", borderRadius: "15px"}}
                                    >
                                        Ticket <i className="fas fa-print"/>
                                    </button>
                                    <button
                                        onClick={() => printA4(props.DetallesVenta, props.Venta, props.extImg,props.cuentasBancarias)}
                                        className="boton__verdeOscuro--letras ml-1"
                                        tabIndex="5"
                                        style={{height: "100%", borderRadius: "15px"}}
                                    >
                                        A4 <i className="fas fa-print"/>
                                    </button>
                                </div>
                                <div className="botones-resp">
                                    <button
                                        onClick={() => downprintA4(props.DetallesVenta, props.Venta, props.extImg)}
                                        className="boton__verdeOscuro--letras ml-1"
                                        tabIndex="5"
                                    >
                                        A4 <i className="fas fa-print"/>
                                    </button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal isOpen={showModalValidacion} onClose={() => setShowModalValidacion(false)} width='900px'
                   title="Validar venta crédito">
                <label className="font-weight-bold" htmlFor="inputValidacion">
                    Código
                </label>
                <input type="password"
                       name="inputValidacion"
                       className="form-control mb-2"
                       defaultValue={""}
                       value={typedCodigoUsuario}
                       onChange={e => setTypedCodigoUsuario(e.target.value)}/>

                <div className="d-flex justify-content-end">
                <button
                    className="btn btn-verde-mfp-letra-normal"
                    title="Validar"
                    onClick={validarCodigoUsuario}
                >Validar</button>
                </div>
            </Modal>
        </>
    );
};

export default PagosUI;
